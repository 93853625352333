<template>
<div >
  <div class="title_main">Information Guide</div>
</div>

</template>
<script>
import { ConfigService } from '@/services'
import { mapState, mapMutations } from 'vuex'
import { DBSupportService } from '@/services'

import __C from '@/primitives/_constant_'

export default{ 
  name:'',
  components:{},
  data: () => ({
    configService: null,
    titles: {},
  }),
  created(){
    this.configService = new ConfigService()
    this.configService.setEnvironment({
      layout: {
        lnb: {
          visible: false
        },
        gnb: {
          visible: false
        },
        anb: {
          visible: false
        },
        snb: {
          visible: false
        },
        fnb: {
          visible: false
        }
      }
    })
    let currentConfig = this.configService.getEnvironment()
    this.setLayout(currentConfig.layout)
    this.queryLibService = new DBSupportService()
    
  },
  mounted(){
    // this.queryLibService.getSqlQueryResult({
    //   idx: 0, 
    //   name: 'China1 Vendor Map Titles',
    // }).then(res => {
    //   this.titles = res ? res[0] : {}
    // })
  },
  unmounted(){},
  methods:{
    ...mapMutations(__C.STORE_NAMESPACE.CONFIG, ['setLayout']),
    
  }
}
</script>
<style lang="stylus" scoped>
  .title_main {
    color: #000;
    font-size: 2.1rem;
  }
</style>
